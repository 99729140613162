<template>
  <button
    :class="[
      'favorite-service-toggle-button',
      {
        'favorite-service-toggle-button--is-active': isFavorite,
        'favorite-service-toggle-button--is-loading': loading,
        'favorite-service-toggle-button--is-hovered': isHovered,
        'favorite-service-toggle-button--is-icon-only': isIconOnly,
      }
    ]"
    :title="isIconOnly && captionText"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click.stop.prevent="toggleFavorite"
  >
    <span class="favorite-service-toggle-button__caption">
      {{ captionText }}
    </span>

    <span class="favorite-service-toggle-button__icon">
      <esmp-icon class="favorite-icon-filled" name="favorite-filled" />

      <esmp-icon class="favorite-icon-empty" name="favorite" />

      <esmp-loader
        class="favorite-icon-loading"
        size="small"
      />
    </span>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'FavoriteServiceToggleButton',
  props: {
    isIconOnly: {
      type: Boolean,
      default: false,
    },

    serviceId: {
      type: [String, Number],
      default: undefined,
    },

    sourceSystem: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      isHovered: false,
    };
  },

  computed: {
    ...mapState('services', ['favoriteServices']),

    isFavorite() {
      if (!this.favoriteServices) return false;
      return this.favoriteServices.some((item) => item.id === this.serviceId);
    },

    captionText() {
      const isIn = 'В избранном';
      const add = 'Добавить в избранное';
      const remove = 'Удалить из избранного';
      const loadingIn = 'Добавление...';
      const loadingOut = 'Удаление...';

      if (this.loading && !this.isFavorite) return loadingIn;
      if (this.loading && this.isFavorite) return loadingOut;
      if (this.isHovered && this.isFavorite) return remove;
      if (this.isFavorite) return isIn;

      return add;
    },
  },

  methods: {
    ...mapActions('services', ['addToFavorite', 'deleteFavorites']),

    async toggleFavorite() {
      this.loading = true;

      if (this.isFavorite) {
        try {
          await this.deleteFavorites({
            serviceId: this.serviceId,
            sourceSystem: this.sourceSystem,
          });

          this.$EsmpNotify.$show('Услуга удалена из избранного', 'success');
          this.$emit('deleted', this.serviceId);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          await this.addToFavorite({
            serviceId: this.serviceId,
            sourceSystem: this.sourceSystem,
          });

          this.$EsmpNotify.$show('Услуга добавлена в избранное', 'success');
          this.$emit('added', this.serviceId);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.favorite-service-toggle-button {
  $parent: &;

  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  transition: color $base-animation-time $base-animation-function;

  &__caption {
    font-size: 13px;
    line-height: 20px;

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__icon {
    position: relative;
    width: 24px;
    height: 24px;

    .favorite-icon-empty,
    .favorite-icon-filled,
    .favorite-icon-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity $base-animation-time $base-animation-function,
        color $base-animation-time $base-animation-function;
    }

    .favorite-icon-filled,
    .favorite-icon-loading {
      opacity: 0;
    }
  }

  &--is-active {
    .favorite-icon-empty {
      opacity: 0;
    }

    .favorite-icon-filled {
      opacity: 1;
      color: $color-yellow;
    }
  }

  &--is-loading {
    .favorite-icon-empty,
    .favorite-icon-filled {
      opacity: 0;
    }

    .favorite-icon-loading {
      opacity: 1;
    }
  }

  &--is-icon-only {
    #{$parent}__caption {
      display: none;
    }
  }

  &:hover {
    .favorite-icon-empty,
    .favorite-icon-filled {
      color: $color-yellow;
    }
  }
}
</style>
