<template>
  <div class="service-list-item" @click="goToCreateTicket">
    <div class="service-list-item__content">
      <h4 class="service-list-item__name service-list-item__item" v-html="service.name" />

      <p
        class="service-list-item__description service-list-item__item"
        v-if="service.description"
        v-html="service.description"
      />

      <div class="service-list-item__tags">
        <esmp-tag
          v-if="service.type"
          color="yellow"
        >
          {{ service.type }}
        </esmp-tag>

        <esmp-tag
          v-if="service.category"
          color="green"
        >
          {{ service.category }}
        </esmp-tag>

        <esmp-tag
          v-if="service.group"
          color="blue"
        >
          {{ service.group }}
        </esmp-tag>
      </div>
    </div>

    <div class="service-list-item__actions">
      <favorite-service-toggle-button
        :service-id="service.id"
        :source-system="service.sourceSystem"
        @added="$emit('service-added', $event)"
        @deleted="$emit('service-deleted', $event)"
      />
    </div>
  </div>
</template>

<script>
import FavoriteServiceToggleButton from '@/components/favorite-service-toggle-button';
import { getCreateTicketPath } from '@/helpers/pathGenerator';

export default {
  name: 'ServiceListItem',

  components: { FavoriteServiceToggleButton },

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async goToCreateTicket() {
      this.$emit('go-to-create-ticket', {
        serviceId: this.service.id,
        sourceSystem: this.service.sourceSystem,
      });

      await this.$router.push({
        path: getCreateTicketPath(this.service.id, this.service.sourceSystem),
      });
    },
  },
};
</script>

<style lang="scss">
.service-list-item {
  background: $color-white;
  box-shadow: $base-shadow;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;

  @include for-size(phone-portrait-down) {
    padding: 16px;
  }

  &__content {
    max-width: 584px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 20px;

    @include for-size(phone-portrait-down) {
      font-size: 13px;
    }
  }

  &__description {
    color: $color-black-op-50;
    margin-bottom: 16px;

    @include for-size(phone-portrait-down) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .esmp-tag {
      font-size: 12px;
      line-height: 16px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .esmp-button {
    color: $color-black;
  }
}
</style>
