var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'favorite-service-toggle-button',
    {
      'favorite-service-toggle-button--is-active': _vm.isFavorite,
      'favorite-service-toggle-button--is-loading': _vm.loading,
      'favorite-service-toggle-button--is-hovered': _vm.isHovered,
      'favorite-service-toggle-button--is-icon-only': _vm.isIconOnly,
    }
  ],attrs:{"title":_vm.isIconOnly && _vm.captionText},on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleFavorite.apply(null, arguments)}}},[_c('span',{staticClass:"favorite-service-toggle-button__caption"},[_vm._v(" "+_vm._s(_vm.captionText)+" ")]),_c('span',{staticClass:"favorite-service-toggle-button__icon"},[_c('esmp-icon',{staticClass:"favorite-icon-filled",attrs:{"name":"favorite-filled"}}),_c('esmp-icon',{staticClass:"favorite-icon-empty",attrs:{"name":"favorite"}}),_c('esmp-loader',{staticClass:"favorite-icon-loading",attrs:{"size":"small"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }